<template>
  <div class="container">
    <div
      class="row u-align-items-center u-justify-content-center u-margin-top u-margin-bottom"
    >
      <div
        v-if="pageStatus === 'started'"
        class="es-xs-12 es-md-6 es-lg-5 o-card"
      >
        <div>
          <h4 class="u-color-success">{{ $t("account.title") }}</h4>
          <p class="u-color-muted">{{ $t("account.description") }}</p>
        </div>
        <form @submit="LOGIN">
          <div class="u-margin-top">
            <BaseInput
              v-model="form.username"
              block
              icon="email"
              :mandotary="validationActive"
              :label="$t('account.username')"
              :placeholder="$t('account.usernameEnter')"
              type="email"
            ></BaseInput>
            <BaseInput
              v-model="form.password"
              block
              icon="ui-lock"
              :minlength="6"
              :mandotary="validationActive"
              :label="$t('account.password')"
              :placeholder="$t('account.passwordEnter')"
              type="password"
            ></BaseInput>
          </div>
          <div class="u-margin-top">
            <Button
              type="submit"
              @submit="LOGIN"
              variant="primary"
              block
              ref="button"
              icon="sign-in"
            >
              {{ $t("account.button") }}
            </Button>
          </div>
        </form>
        <div class="u-margin-top">
          <p>
            <small>
              <i class="icofont-info"></i>
              {{ $t("account.description2") }}</small
            >
          </p>
        </div>
        <div class="u-text-align-center u-margin-top">
          <a target="_blank" href="https://rezzervasyon.com">
            <img src="@/assets/images/Logo.png" alt="" />
          </a>
          <p>
            <small
              ><i>{{
                $t("track.footer", { version: new Date().getFullYear() })
              }}</i></small
            >
          </p>
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img width="120" src="@/assets/images/google-play.webp" alt="" />
            </a>
            <a
              target="_blank"
              class="u-margin-left-xsmall-sm-up u-margin-top-md-down u-display-inline-block"
              href="https://rezzervasyon.com"
            >
              <img width="120" src="@/assets/images/app-store.webp" alt="" />
            </a>
          </div>
        </div>
        <div class="u-margin-top">
          <p class="u-color-muted">
            <small class="u-font-size-xsmall">
              <i class="icofont-info-circle"></i>
              {{ $t("account.description3") }}</small
            >
          </p>
        </div>
      </div>
      <div
        class="es-xs-12 es-md-6 es-lg-5 o-card"
        v-else-if="pageStatus === 'profile'"
      >
        <div class="u-text-align-center">
          <img src="@/assets/images/confirmed.gif" width="100" alt="" />
          <h5>{{ user.fullname }}</h5>
          <p class="u-color-muted">
            {{ $t("account.deleteAccountDescription") }}
          </p>
          <div class="u-margin-top">
            <Button block variant="danger" @click="removeAccount">
              {{ $t("account.deleteAccount") }}
            </Button>
          </div>
        </div>
        <div class="u-text-align-center u-margin-top">
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img src="@/assets/images/google-play.webp" alt="" />
            </a>
            <a
              target="_blank"
              class="u-margin-left-xsmall-sm-up u-margin-top-md-down u-display-inline-block"
              href="https://rezzervasyon.com"
            >
              <img src="@/assets/images/app-store.webp" alt="" />
            </a>
          </div>
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img src="@/assets/images/Logo.png" alt="" />
            </a>
          </div>
          <p>
            <small
              ><i>{{
                $t("track.footer", { version: new Date().getFullYear() })
              }}</i></small
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Inputs/BaseInput.vue";
import Button from "@/components/Base/Button.vue";
import Requests from "@/services/Requests";

export default {
  components: {
    BaseInput,
    Button,
  },
  data() {
    return {
      user: null,
      validationActive: false,
      form: {
        password: "",
        username: "",
      },
      pageStatus: "started",
    };
  },
  methods: {
    iniFrame() {
      if (window.location !== window.parent.location) {
        // The page is in an iFrames

        window.parent.postMessage(this.user, "*");
      }
    },
    async LOGIN(e) {
      e.preventDefault();
      this.validationActive = true;
      const loading = this.$refs.button.SET_LOADING;
      loading(true);
      const body = {
        username: this.form.username,
        password: this.form.password,
      };
      const response = await Requests.Auth.UserLogin(body);
      if (response.message === "OK") {
        this.user = response.data;
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("account.success"),
          variant: "success",
          duration: 2000,
        });
        this.iniFrame();
        this.pageStatus = "profile";
      } else if (response.message === "NOT_FOUND") {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t(`account.${response.message}`),
          variant: "danger",
          duration: 4000,
        });
      } else {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t(`message.${response.message}.description`),
          variant: "danger",
          duration: 4000,
        });
      }
      loading(false);
    },
    async removeAccount(e, loading) {
      loading(true);
      const response = await Requests.User.Delete({
        params: { id: this.user.id },
      });
      if (response.message === "OK") {
        this.user = response.data;
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("account.removeSuccess"),
          variant: "success",
          duration: 2000,
        });
        setTimeout(() => {
          this.pageStatus = "started";
        }, 2000);
      } else {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t(`message.${response.message}.description`),
          variant: "danger",
          duration: 4000,
        });
      }
      loading(false);
    },
  },
};
</script>

<style></style>
